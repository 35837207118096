<template>
  <div class="item-department grid box overflow-h">
    <!-- Head -->
    <div
      class="item-department__head flex ai-c jc-sb ggap-10 p-5"
      :class="{ active: isOpen }"
      @click.stop.prevent="isOpen = !isOpen"
    >
      <div class="flex ai-c ggap-10">
        <button
          type="button"
          class="item-department__head-btn btn sm rounded cube-24"
          :class="isOpen ? 'primary' : 'grey'"
        >
          <BaseIcon class="ic-16" :class="isOpen ? 'white' : 'black'" :icon="isOpen ? 'arrow-down' : 'arrow-right'" />
        </button>
        <h3 class="title">{{ item.name }}</h3>
        <small class="marker grey" v-tippy="'Обязательные участники'">{{ item.users.length }}</small>
        <small
          v-if="propValue.manual_users && propValue.manual_users.length"
          class="marker primary-light"
          v-tippy="'Дополнительные участники'"
        >
          {{ propValue.manual_users.length }}
        </small>
      </div>

      <div v-if="!isRead" class="flex ai-c ggap-10">
        <button
          type="button"
          v-if="!isDelete"
          class="btn transparent-grey sm pl-10 pr-10"
          @click.stop.prevent="isDelete = !isDelete"
        >
          <BaseIcon class="ic-16 red" icon="delete" />
        </button>
        <div v-else class="flex ai-c ggap-10">
          <button type="button" class="btn cancel sm pl-10 pr-10" @click.stop.prevent="deleteAction">
            <BaseIcon class="ic-16 red" icon="delete" />
            Удалить
          </button>
          <button type="button" class="btn grey sm pl-10 pr-10" @click.stop.prevent="isDelete = false">
            <BaseIcon class="ic-16 black" icon="close" />
          </button>
        </div>
        <button type="button" class="btn grey sm pl-10 pr-10 handle" @click.stop.prevent>
          <BaseIcon class="ic-16 black" icon="menu" />
        </button>
      </div>
    </div>

    <!-- Body -->
    <div v-if="isOpen" class="item-department__body grid ggap-5 p-10">
      <h4 v-if="!isRead" class="title">Обязательные участники</h4>
      <div v-if="item.users && item.users.length" class="box overflow-h grid">
        <UsersDragItem
          v-for="(element, index) of item.users"
          :key="element.id"
          :item="element"
          :index="index + 1 + length"
          :isRead="isRead"
          isStatic
        />
      </div>
      <BaseAlert v-else type="yellow">Обязательные участники не найдены</BaseAlert>

      <div v-if="isRead && item.manual_users && item.manual_users.length" class="box overflow-h">
        <UsersDragItem
          v-for="(element, index) of item.manual_users"
          :key="element.id"
          :item="element"
          :index="item.users.length + index + 1 + length"
          :isRead="isRead"
        />
      </div>

      <template v-else-if="!isRead">
        <h4 class="title mt-20">Дополнительные участники</h4>
        <UsersDrag :length="item.users.length + length" :usersStatic="item.users" v-model="propValue.manual_users" />
      </template>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, toRefs, ref, computed } from 'vue'
import { BaseIcon, BaseAlert } from '@/components'
import UsersDrag from '../UsersDrag.vue'
import UsersDragItem from '../UsersDragItem.vue'

// Emits
const emits = defineEmits(['update:item', 'deleteAction'])

// Props
const props = defineProps({
  isRead: {
    type: Boolean,
    default: false
  },
  length: {
    type: Number,
    default: 0
  },
  item: {
    type: Object,
    default: () => ({})
  }
})

// Data
const { isRead, length, item } = toRefs(props)
const isOpen = ref(false)
const isDelete = ref(false)

// Computed
const propValue = computed({
  get: () => item.value,
  set: (val) => emits('update:item', val)
})

// Created
if (!propValue.value?.manual_users) {
  propValue.value.manual_users = []
}

// Methods
function deleteAction() {
  emits('deleteAction', item.value.id)
}
</script>

<style lang="scss" scoped>
.item-department {
  &__head {
    height: 40px;

    &:hover {
      background-color: var(--grey-l);
    }
    &:active {
      background-color: var(--grey);
    }
    &.active {
      background-color: var(--box);
    }
  }

  &__body {
    background-color: var(--grey);
    border-top: var(--b1);

    &-user {
      border-bottom: var(--b1);

      &:last-child {
        border: 0;
      }

      &:hover {
        background-color: var(--grey-l);
      }

      &-check {
        width: 50px;
        height: 100%;
      }
    }
  }

  &.ghost {
    background-color: var(--primary-el);
  }

  &.ghost &__head,
  &.ghost &__body {
    opacity: 0;
  }
}
</style>
