<template>
  <div class="item-department grid box overflow-h">
    <!-- Head -->
    <div
      class="item-department__head flex ai-c jc-sb ggap-10 p-5"
      :class="{ active: isOpen }"
      @click.stop.prevent="isOpen = !isOpen"
    >
      <div class="flex ai-c ggap-10">
        <button
          type="button"
          class="item-department__head-btn btn sm rounded cube-24"
          :class="isOpen ? 'primary' : 'grey'"
        >
          <BaseIcon class="ic-16" :class="isOpen ? 'white' : 'black'" :icon="isOpen ? 'arrow-down' : 'arrow-right'" />
        </button>
        <h3 class="title">{{ item.name }}</h3>
        <small class="marker grey" v-tippy="'Обязательные участники'">{{ item.users.length }}</small>
        <small
          v-if="propValue.manual_users && propValue.manual_users.length"
          class="marker primary-light"
          v-tippy="'Выбранные участники'"
        >
          {{ propValue.manual_users.length }}
        </small>
      </div>

      <template v-if="!isRead">
        <button v-if="!isDelete" class="btn transparent-grey sm pl-10 pr-10" @click.stop.prevent="isDelete = !isDelete">
          <BaseIcon class="ic-16 red" icon="delete" />
        </button>
        <div v-else class="flex ai-c ggap-10">
          <button class="btn cancel sm pl-10 pr-10" @click.stop.prevent="deleteAction">
            <BaseIcon class="ic-16 red" icon="delete" />
            Удалить
          </button>
          <button class="btn grey sm pl-10 pr-10" @click.stop.prevent="isDelete = false">
            <BaseIcon class="ic-16 black" icon="close" />
          </button>
        </div>
      </template>
    </div>

    <!-- Body -->
    <div v-if="isOpen" class="item-department__body flex fw ggap-5 p-10">
      <template v-if="item.users && item.users.length">
        <ItemUser
          v-for="user of item.users"
          :key="user.id"
          :item="{
            id: user.id,
            avatar: user.avatar,
            text: user.fullname || user.text,
            position: user.specialization || user.position || 'Без должности'
          }"
          :isLink="isRead"
          :isRemove="false"
          isPinned
        />
      </template>
      <template v-if="propValue.manual_users && propValue.manual_users.length">
        <ItemUser
          v-for="user of propValue.manual_users"
          :key="user.id"
          :item="{
            id: user.id,
            avatar: user.avatar,
            text: user.fullname || user.text,
            position: user.specialization || user.position || 'Без должности'
          }"
          :isLink="isRead"
          :isRemove="!isRead"
          @remove="removeUser(user.id)"
        />
      </template>
      <SearchInput v-if="!isRead" :usersStatic="item.users" :usersCustom="propValue.manual_users" @setUser="setUser" />
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps, toRefs, ref, computed } from 'vue'
import { BaseIcon, ItemUser } from '@/components'
import SearchInput from './SearchInput.vue'

// Emits
const emits = defineEmits(['update:item', 'deleteAction'])

// Props
const props = defineProps({
  isRead: {
    type: Boolean,
    default: false
  },
  item: {
    type: Object,
    default: () => ({})
  }
})

// Data
const { isRead, item } = toRefs(props)
const isOpen = ref(false)
const isDelete = ref(false)

// Computed
const propValue = computed({
  get: () => item.value,
  set: (val) => emits('update:item', val)
})

// Created
if (!propValue.value?.manual_users) {
  propValue.value.manual_users = []
}

// Methods
function deleteAction() {
  emits('deleteAction', item.value.id)
}
function setUser(item) {
  if (propValue.value?.manual_users) {
    const index = propValue.value.manual_users.findIndex((el) => el.id === item.id)
    if (index === -1) propValue.value.manual_users.push({ ...item })
    else propValue.value.manual_users.splice(index, 1)
  } else {
    propValue.value.manual_users = []
  }
}
function removeUser(id) {
  const index = propValue.value.manual_users.findIndex((el) => el.id === id)
  if (index !== -1) propValue.value.manual_users.splice(index, 1)
}
</script>

<style lang="scss" scoped>
.item-department {
  &__head {
    height: 40px;

    &:hover {
      background-color: var(--grey-l);
    }
    &:active {
      background-color: var(--grey);
    }
    &.active {
      background-color: var(--box);
    }
  }

  &__body {
    background-color: var(--grey);
    border-top: var(--b1);
  }
}
</style>
